import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';
import ThermostatIcon from '../svg/Thermostat';
import BoltIcon from '../svg/Bolt';
import PricingSection from '../components/sections/Pricing';
import CallToActionSection from '../components/sections/CalltoAction';

export default () => (
  <Layout>
    {/* Hero */}
    <section id="#" className="pt-18 md:pt-20">
      <div className="container pt-10 pb-25 bg-gray-200 mx-auto px-12 lg:flex items-center rounded-lg text-center">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Knowing Is Saving.
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Apply your electric plan rates to your actual kwh usage.
          </p>
          <p className="mt-8 md:mt-12">
            <Button size="lg">Get Started</Button>
          </p>
          <p className="mt-4 text-gray-600">Sed fermentum felis ut cursu</p>
        </div>
        <div className="lg:w-1/2 pb-16">
          <HeroImage />
        </div>
      </div>
    </section>

    {/* Alternate features */}
    <section id="analytics" className="border-b lg:pb-16 lg:pt-20">
      <h2 className="w-full my-0 text-4xl font-bold leading-tight text-center text-gray-800">
        Power Analytics
      </h2>
      <div className="w-full mb-4">	
        <div className="h-1 bg-gray-400 mx-auto w-64 opacity-25 my-1 py-0 rounded-t"></div>
      </div>
      <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-2xl font-semibold leading-tight">Time-based Analysis</h3>
            <p className="mt-4 text-xl font-light leading-relaxed">
              See when most of your power is used. Know how much its costing you.
            </p>
          </div>
        }
        secondarySlot={<SvgCharts />}
      />
      <SplitSection
        reverseOrder
        primarySlot={
          <div className="lg:pl-32 xl:pl-48">
            <h3 className="text-2xl font-semibold leading-tight">Money Saving Data</h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              Take action using your data, no more guessing why your bill is what it is.
            </p>
          </div>
        }
        secondarySlot={<BoltIcon />}
      />
    </section>

    {/* Title cards */}
    <section id="services" className="border-b py-8 lg:pb-20 lg:pt-24">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-4xl font-semibold text-center text-gray-800">
          Main Features
        </h2>
      <div className="w-full mb-4">	
        <div className="h-1 bg-gray-400 mx-auto w-48 opacity-25 my-1 py-0 rounded-t"></div>
      </div>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Service One</p>
              <p className="mt-4">
                An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio
                velna vitae auctor integer.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Service Two</p>
              <p className="mt-4">
                An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio
                velna vitae auctor integer.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Service Three</p>
              <p className="mt-4">
                An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio
                velna vitae auctor integer.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    

    {/* <PricingSection /> */}

    <section id="testimonials" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">What customers are saying</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section>

    <CallToActionSection />
  </Layout>
);
