import React from 'react';

const BoltIcon = () => (
  <svg
    viewBox="0 0 200 200"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
  >
    <circle cx="100" cy="100" r="100" fill="#4299e1" opacity=".5" />
    <path
      d="M80.87,36.983c0.227,-0.88 1.037,-1.482 1.945,-1.448c8.892,0.341 53.339,2.042 65.606,2.511c0.67,0.026 1.279,0.398 1.609,0.982c0.329,0.585 0.332,1.299 0.006,1.886c-5.99,10.798 -27.882,50.258 -33.872,61.057c-0.326,0.586 -0.323,1.3 0.006,1.885c0.329,0.585 0.939,0.957 1.609,0.982c5.366,0.206 16.338,0.626 22.725,0.87c0.793,0.03 1.488,0.543 1.75,1.293c0.262,0.749 0.038,1.583 -0.563,2.101c-13.241,11.403 -58.023,49.97 -72.499,62.436c-0.636,0.547 -1.552,0.622 -2.268,0.184c-0.716,-0.438 -1.068,-1.288 -0.87,-2.103c3.617,-14.924 13.081,-53.975 15.475,-63.851c0.137,-0.564 0.013,-1.158 -0.336,-1.621c-0.35,-0.462 -0.889,-0.743 -1.468,-0.765c-3.482,-0.133 -9.96,-0.381 -13.463,-0.515c-0.584,-0.022 -1.127,-0.308 -1.476,-0.777c-0.349,-0.469 -0.467,-1.071 -0.32,-1.637c2.754,-10.658 14.148,-54.74 16.404,-63.47Z"
      fill="#231f20"
      fillOpacity=".35"
    />
    <path
      d="M80.87,33.983c0.227,-0.88 1.037,-1.482 1.945,-1.448c8.892,0.341 53.339,2.042 65.606,2.511c0.67,0.026 1.279,0.398 1.609,0.982c0.329,0.585 0.332,1.299 0.006,1.886c-5.99,10.798 -27.882,50.258 -33.872,61.057c-0.326,0.586 -0.323,1.3 0.006,1.885c0.329,0.585 0.939,0.957 1.609,0.982c5.366,0.206 16.338,0.626 22.725,0.87c0.793,0.03 1.488,0.543 1.75,1.293c0.262,0.749 0.038,1.583 -0.563,2.101c-13.241,11.403 -58.023,49.97 -72.499,62.436c-0.636,0.547 -1.552,0.622 -2.268,0.184c-0.716,-0.438 -1.068,-1.288 -0.87,-2.103c3.617,-14.924 13.081,-53.975 15.475,-63.851c0.137,-0.564 0.013,-1.158 -0.336,-1.621c-0.35,-0.462 -0.889,-0.743 -1.468,-0.765c-3.482,-0.133 -9.96,-0.381 -13.463,-0.515c-0.584,-0.022 -1.127,-0.308 -1.476,-0.777c-0.349,-0.469 -0.467,-1.071 -0.32,-1.637c2.754,-10.658 14.148,-54.74 16.404,-63.47Z"
      fill="#f0cb87"
    />
  </svg>
);

export default BoltIcon;
